import React from "react"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import Head from "../components/head"

import testimonials from '../../ben-edit/testimonials.json'

export default () => (
  <Layout>
    <Head title="Testimonials" />

    {testimonials.map(testimonial => (
      <div
        key={testimonial.person}
        css={css`
          margin-bottom: 4rem;

          * {
            color: var(--text-light);
          }
        `}
      >
        <p
          css={css`
            border-left: .5rem solid var(--text-light);
            padding: 1rem 2rem;
            margin-bottom: 1rem;
            font-style: italic;
            letter-spacing: .2px;
            font-size: 18px;
            line-height: 1.4;
          `}
        >
          {testimonial.quote}
        </p>
        <p
          css={css`
            text-align: right;
          `}
        >
          — {testimonial.person}
        </p>
      </div>
    ))}
  </Layout>
)
